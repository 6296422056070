<template>
    <template v-if="paragraph">
        <component v-if="paragraph.constructor == ServiceParagraphModel && type" :is="paragraph.tag ?? type.tag ?? defaultTag">
            {{ paragraph.text }}
            <ServiceParagraph v-for="child,index in paragraph.children" :key="index"
                :paragraph="child"
                :default-tag="type.defaultChildType.tag" />
        </component>
        <ServiceParagraph v-else-if="Array.isArray(paragraph)"
            v-for="p, index in paragraph" :key="index"
            :paragraph="getParagraphFrom(p)"/>
        <ServiceParagraph v-else-if="paragraph" :paragraph="getParagraphFrom(paragraph)"/>
    </template>
</template>
<script setup lang="ts">
import { Paragraph } from '@/data/service/Service';
import ServiceParagraphModel, { ParagraphType } from '@/data/service/ServiceParagraph';
import { computed, reactive } from 'vue';

const props = withDefaults(defineProps<{
    paragraph:ServiceParagraphModel | Paragraph | Paragraph[],
    defaultTag:string
}>(),{
    defaultTag:"p"
});
function getParagraphFrom(d:ServiceParagraphModel | Paragraph | Paragraph[]){
    if(d instanceof ServiceParagraphModel) return d;
    return reactive(ServiceParagraphModel.from(d));
}
const type = computed(()=>props.paragraph instanceof ServiceParagraphModel?ParagraphType.from(props.paragraph?.type??""):undefined);
</script>