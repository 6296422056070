import { createRouter, createWebHistory } from "vue-router";
import MainPageVue from "@/components/pages/main-page/MainPage.vue";
const routes = [
  {
    path: "/",
    name: "",
    component: MainPageVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
