<template>
	<div ref="el" class="service" tabindex="0" v-outside:mousedown="()=>more(false)">
		<div class="contents" :class="{expanded:transition}">
			<span class="service-title">{{ title }}</span>
			<ExpandedSection class="paragraphs"
				:value="expanded"
				@transitionend="transitionend">
				<div stack>
					<div :class="['text',{ show:!expanded }]">
						<ServiceParagraph :paragraph="smallText" />
					</div>
					<div :class="['text',{ show:expanded }]">
						<ServiceParagraph :paragraph="text" />
					</div>
				</div>
			</ExpandedSection>
			<div class="more" @click="more()">
				<template v-if="expanded">кратко</template>
				<template v-else>подробнее</template>
			</div>
			<div class="circle">
				<img loading="lazy" :src="image" :alt="alt" />
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import ExpandedSection from '@/components/ui/expanded-section.vue';
import ServiceParagraph from './ServiceParagraph.vue';
// import { onBeforeUnmount, onMounted, ref } from 'vue';
import {  ref } from 'vue';
import { Paragraph } from '@/data/service/Service';

const el = ref(undefined);
const emit = defineEmits(['more'])
defineProps<{
	title:string,
    image:string,
	smallText:Paragraph | Paragraph[],
	text:Paragraph | Paragraph[],
	alt:string
}>();
const expanded = ref(false);
const transition = ref(false);
function more(value = !expanded.value){
	expanded.value = value;
	if(expanded.value) transition.value = true;
	emit('more');
}
// function onMousedown(e){
// 	if(inThisElement(e.target)) return;
// 	more(false)
// }
// onMounted(()=>{
// 	document.addEventListener("mousedown",onMousedown)
// });
// onBeforeUnmount(()=>{
// 	document.addEventListener("mousedown",onMousedown)
// })
// /**
//  *
//  * @param {Element} element
//  */
// function inThisElement(element){
// 	if(element == el.value) return true;
// 	if(!element.parentElement) return false;
// 	return inThisElement(element.parentElement);
// }
function transitionend(){
	transition.value = expanded.value;
}
</script>
<style scoped lang="scss">
@import '../../../assets/styles/theme/colors.scss';
.service{
	width: 300px;
	height: 200px;
	// background: white;
	// box-shadow: -1px 1px 4px 0px gray;
	// border-radius: 8px;
	// padding: 8px 15px;
	position: relative;
	.contents{
		display: flex;
		flex-direction: column;
		background: white;
		box-shadow: -1px 1px 4px 0px gray;
		border-radius: 8px;
		padding: 8px 15px;
		position: absolute;
		min-height: 100%;
		width: 100%;
		&.expanded {
			z-index: 2;
		}
		&>.service-title{
			color: black;
			font-size: 0.9em;
			font-weight: bold;
		}
		.paragraphs{
			font-size: small;
			color: #3C3C3C;
			margin-top: 8px;
			margin-bottom: 0;
			margin-right: 50px;
			min-height: 140px;
		}
		&>.more{
			margin-top: auto;
			margin-left: auto;
			font-size: small;
			color: $primaryColor;
			text-decoration: underline;
			cursor: pointer;
			user-select: none;
		}
		&>.circle{
			position: absolute;
			right: -15px;
			top: -12px;
			width: 80px;
			height: 80px;
			border: 2px solid #238ecc;
			border-radius: 80px;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
			z-index: 1;
			&>img{
				pointer-events: none;
				user-select: none;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
.text{
	transition: all 0.3s ease-in-out;
	opacity: 1;
	&:not(.show){
		opacity: 0;
		user-select: none;
		pointer-events: none;
	}
}
</style>