<template>
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.33331 533.33331" height="533.33331" width="533.33331"
  xml:space="preserve" id="svg2" version="1.1">
    <g transform="matrix(1.3333333,0,0,-1.3333333,0,533.33333)" id="g10">
      <g transform="translate(343.5435,300.7493)" id="g12">
        <path id="path14" style="fill:#6a7a85;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 40.204,17.653 49.818,71.625 8.798,55.021 Z" />
      </g>
      <g transform="translate(306.2274,378.6964)" id="g16">
        <path id="path18" style="fill:#b6c1c9;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 46.114,-22.926 87.134,-6.322 40.977,15.304 Z" />
      </g>
      <g transform="translate(299.9026,323.8111)" id="g20">
        <path id="path22" style="fill:#cfd9de;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 43.641,-23.062 52.438,31.96 6.325,54.885 Z" />
      </g>
      <g transform="translate(303.8,6)" id="g24">
        <path id="path26" style="fill:#6f808a;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 44.96,30.889 55.026,78.024 7.892,47.705 Z" />
      </g>
      <g transform="translate(256.7772,45.3547)" id="g28">
        <path id="path30" style="fill:#8b9ca6;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 47.023,-39.355 54.914,8.35 5.263,46.904 Z" />
      </g>
      <g transform="translate(262.0405,92.2591)" id="g32">
        <path id="path34" style="fill:#96a2a9;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 49.651,-38.554 96.786,-8.235 46.695,28.462 Z" />
      </g>
      <g transform="translate(312.1826,136.5352)" id="g36">
        <path id="path38" style="fill:#73848e;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 50.385,30.884 62.215,91.197 10.287,62.079 Z" />
      </g>
      <g transform="translate(259.1285,175.2029)" id="g40">
        <path id="path42" style="fill:#d2e0e5;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 53.054,-38.668 63.341,23.411 5.31,60.766 Z" />
      </g>
      <g transform="translate(264.4388,235.9688)" id="g44">
        <path id="path46" style="fill:#c7d2d9;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 58.03,-37.355 109.958,-8.237 54.269,24.11 Z" />
      </g>
      <g transform="translate(11.47,294.7375)" id="g48">
        <path id="path50" style="fill:#feffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 33.334,-26.298 29.252,27.399 -4.832,53.976 Z" />
      </g>
      <g transform="translate(6.6384,348.7136)" id="g52">
        <path id="path54" style="fill:#eff8f5;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 34.083,-26.577 87.206,-7.039 51.212,16.139 Z" />
      </g>
      <g transform="translate(332.1581,148.7794)" id="g56">
        <path id="path58" style="fill:#7a8a93;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 -4.845,-35.143 -5.288,-2.646 -13.289,9.731 -20.719,-12.63 2.284,24.542 -72.786,-41.86 -3.594,-47.452 -50.747,-31.288 0.447,47.603 29.044,16.765 1.944,52.547 -38.173,-20.361 1.199,59.727 59.88,29.342 -2.846,-57.183 53.221,28.342 44.33,-32.28 z" />
      </g>
      <g transform="translate(349.3756,242.2659)" id="g60">
        <path id="path62" style="fill:#6c7d88;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 3.166,20.887 -21.4,-10.296 z" />
      </g>
      <g transform="translate(69.486,128.2304)" id="g64">
        <path id="path66" style="fill:#b1c0c6;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 42.571,19.029 39.685,70.991 -4.221,51.539 Z" />
      </g>
      <g transform="translate(34.1898,160.2021)" id="g68">
        <path id="path70" style="fill:#ecf7f3;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 35.296,-31.972 31.075,19.567 -5.502,52.225 Z" />
      </g>
      <g transform="translate(112.0572,147.2594)" id="g72">
        <path id="path74" style="fill:#dff1eb;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 44.379,-38.672 45.578,21.055 -3.221,57.984 Z" />
      </g>
      <g transform="translate(157.6353,168.3142)" id="g76">
        <path id="path78" style="fill:#e2eeed;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 59.879,29.342 12.575,63.773 98.601,102.683 54.09,129.228 -51.895,87.124 -7.563,54.964 -48.799,36.93 Z" />
      </g>
      <g transform="translate(161.9053,315.1975)" id="g80">
        <path id="path82" style="fill:#e3edec;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 52.139,19.38 10.752,42.76 -41.507,25.382 Z" />
      </g>
      <g id="g84">
        <g id="g86">
          <g id="g92">
            <g id="g94">
              <path id="path104" style="fill:url(#linearGradient102);stroke:none"
                d="m 161.863,277.735 49.862,19.807 2.319,37.036 -52.139,-19.38 z" />
            </g>
          </g>
        </g>
      </g>
      <g transform="translate(121.7761,283.1908)" id="g106">
        <path id="path108" style="fill:#fcfefe;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 19.374,-13.685 20.713,8.229 0.042,37.463 -41.506,25.382 z" />
      </g>
      <g transform="translate(211.7249,297.5424)" id="g110">
        <path id="path112" style="fill:#d2dde3;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 46.971,19.295 6.304,42.185 -2.141,39.554 2.319,37.035 Z" />
      </g>
      <g transform="translate(211.7249,297.5424)" id="g114">
        <path id="path116" style="fill:#6e7a81;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 43.103,-25.706 3.868,45.001 z" />
      </g>
      <g transform="translate(256.2359,270.9969)" id="g118">
        <path id="path120" style="fill:#cfdae1;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 36.589,-22.416 25.883,11.498 12.434,-7.222 21.4,10.296 -49.12,28.417 z" />
      </g>
      <g transform="translate(216.4259,198.4468)" id="g122">
        <path id="path124" style="fill:#889399;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 35.991,16.811 39.81,72.55 -46.216,33.64 Z" />
      </g>
      <g id="g126">
        <g id="g128">
          <g id="g134">
            <g id="g136">
              <path id="path150" style="fill:url(#linearGradient148);stroke:none"
                d="m 252.417,215.257 9.688,-5.984 2.334,26.696 28.385,12.612 -36.588,22.416 z" />
            </g>
          </g>
        </g>
      </g>
      <g transform="translate(216.4259,198.4468)" id="g152">
        <path id="path154" style="fill:#a1acb3;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 1.089,-0.791 -2.846,-57.183 53.221,28.342 -8.761,6.388 2.976,34.07 -9.688,5.985 z" />
      </g>
      <g transform="translate(270.9222,97.6731)" id="g156">
        <path id="path158" style="fill:#3c444b;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 17.094,10.418 19.379,34.96 -17.653,13.664 Z" />
      </g>
      <g transform="translate(108.8366,205.2439)" id="g160">
        <path id="path162" style="fill:#f5fbf8;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 41.236,18.034 -3.097,50.195 Z" />
      </g>
      <g transform="translate(65.2646,179.7693)" id="g164">
        <path id="path166" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 43.907,19.452 42.95,35.581 18.479,54.817 -36.576,32.657 Z" />
      </g>
      <g transform="translate(122.5376,50.5823)" id="g168">
        <path id="path170" style="fill:#dcefe9;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 40.637,-38.549 41.084,9.054 -2.034,47.567 Z" />
      </g>
      <g transform="translate(192.6651,76.4013)" id="g172">
        <path id="path174" style="fill:#e7f3ef;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 1.944,52.547 -34.693,33.006 Z" />
      </g>
      <g transform="translate(120.504,98.1495)" id="g176">
        <path id="path178" style="fill:#ecf7f3;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 43.117,-38.514 29.044,16.766 -34.693,33.005 -1.536,-0.819 -6.401,5.579 z" />
      </g>
      <g transform="translate(78.2259,132.1372)" id="g180">
        <path id="path182" style="fill:#e7f2ee;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="m 0,0 42.403,-36.948 -0.125,2.96 29.531,16.017 -37.978,33.093 z" />
      </g>
      <g id="g184">
        <g id="g186">
          <g id="g192">
            <g id="g194">
              <path id="path204" style="fill:url(#radialGradient202);stroke:none"
                d="m 44.804,268.44 49.965,20.405 -0.924,52.829 -53.123,-19.538 z" />
            </g>
          </g>
        </g>
      </g>
      <g id="g206">
        <g id="g208">
          <g id="g214">
            <g id="g216">
              <path id="path226" style="fill:url(#radialGradient224);stroke:none"
                d="m 40.721,322.136 4.083,-53.697 49.965,20.405 -0.924,52.83 z" />
            </g>
          </g>
        </g>
      </g>
      <g id="g228">
        <g id="g230">
          <g id="g236">
            <g id="g238">
              <path id="path246" style="fill:url(#radialGradient244);stroke:none"
                d="m 157.635,168.314 -1.199,-59.727 38.174,20.361 -0.553,-14.921 c 12.732,6.846 22.979,17.724 29.027,30.927 v 0 l -8.415,-4.481 2.846,57.183 z" />
            </g>
          </g>
        </g>
      </g>
      <g id="g248">
        <g id="g250">
          <g id="g256">
            <g id="g258">
              <path id="path270" style="fill:url(#radialGradient268);stroke:none"
                d="m 195.593,73.543 c -13.614,-7.885 -24.671,-17.624 -32.1,-27.502 v 0 l -0.319,-34.008 50.747,31.288 3.03,40.005 c -7.02,-2.394 -14.236,-5.659 -21.358,-9.783" />
            </g>
          </g>
        </g>
      </g>
      <g id="g272">
        <g id="g274">
          <g id="g280">
            <g id="g282">
              <path id="path290" style="fill:url(#radialGradient288);stroke:none"
                d="m 215.123,140.715 c -0.003,-0.347 -0.005,-0.696 -0.005,-1.044 v 0 c 0,-15.934 3.578,-31.037 9.973,-44.541 v 0 l 65.21,37.503 -2.285,-24.542 20.72,12.63 13.289,-9.731 5.288,2.647 4.845,35.142 -19.938,-12.244 -44.33,32.28 z" />
            </g>
          </g>
        </g>
      </g>
      <g id="g292">
        <g clip-path="url(#clipPath298)" id="g294">
          <g id="g300">
            <g id="g302" />
            <g id="g330">
              <g id="g328" clip-path="url(#clipPath306)">
                <g id="g326">
                  <g id="g324">
                    <g id="g322">
                      <g id="g320">
                        <path id="path318" style="fill:url(#radialGradient316);stroke:none"
                          d="m 208.079,194.459 c -10.045,-20.115 6.025,-48.522 35.911,-63.448 29.885,-14.928 62.252,-10.711 72.296,9.402 10.05,20.115 -6.024,48.523 -35.91,63.451 -29.886,14.926 -62.252,10.711 -72.297,-9.405" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g332">
        <g clip-path="url(#clipPath338)" id="g334">
          <g id="g340">
            <g id="g342" />
            <g mask="url(#mask386)" id="g414">
              <g id="g416" />
              <g id="g446">
                <g id="g444" clip-path="url(#clipPath420)">
                  <g id="g442">
                    <g id="g440">
                      <g id="g438">
                        <g id="g436">
                          <path id="path434" style="fill:url(#radialGradient432);stroke:none"
                            d="m 123.354,294.049 c -0.319,-18.455 34.902,-34.028 78.671,-34.788 43.765,-0.76 79.507,13.583 79.826,32.038 0.319,18.453 -34.9,34.029 -78.669,34.788 -43.767,0.76 -79.506,-13.585 -79.828,-32.038" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g448">
            <g id="g450" />
            <g mask="url(#mask494)" id="g522">
              <g id="g524" />
              <g id="g554">
                <g id="g552" clip-path="url(#clipPath528)">
                  <g id="g550">
                    <g id="g548">
                      <g id="g546">
                        <g id="g544">
                          <path id="path542" style="fill:url(#radialGradient540);stroke:none"
                            d="m 134.665,292.733 c 2.219,-14.705 32.29,-22.364 67.167,-17.104 34.871,5.259 61.346,21.443 59.128,36.147 -2.214,14.706 -32.285,22.363 -67.162,17.103 -34.878,-5.259 -61.351,-21.443 -59.133,-36.146" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g556">
        <g clip-path="url(#clipPath562)" id="g558">
          <g id="g564">
            <g id="g566" />
            <g mask="url(#mask610)" id="g638">
              <g id="g640" />
              <g id="g670">
                <g id="g668" clip-path="url(#clipPath644)">
                  <g id="g666">
                    <g id="g664">
                      <g id="g662">
                        <g id="g660">
                          <path id="path658" style="fill:url(#radialGradient656);stroke:none"
                            d="m 112.95,282.469 c 1.819,-18.366 38.605,-29.76 82.17,-25.445 43.56,4.311 77.398,22.699 75.579,41.064 -1.817,18.366 -38.603,29.76 -82.166,25.445 -43.562,-4.311 -77.399,-22.697 -75.583,-41.064" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g672">
        <g clip-path="url(#clipPath678)" id="g674">
          <g id="g680">
            <g id="g682" />
            <g mask="url(#mask726)" id="g754">
              <g id="g756" />
              <g id="g786">
                <g id="g784" clip-path="url(#clipPath760)">
                  <g id="g782">
                    <g id="g780">
                      <g id="g778">
                        <g id="g776">
                          <path id="path774" style="fill:url(#radialGradient772);stroke:none"
                            d="m 109.399,94.725 c 18.208,-3.018 38.774,29.542 45.932,72.727 7.167,43.182 -1.791,80.638 -19.998,83.658 -18.206,3.019 -38.774,-29.54 -45.932,-72.721 -7.165,-43.187 1.791,-80.643 19.998,-83.664" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g788">
        <g clip-path="url(#clipPath794)" id="g790">
          <g id="g796">
            <g id="g798" />
            <g mask="url(#mask840)" id="g868">
              <g id="g870" />
              <g id="g898">
                <g id="g896" clip-path="url(#clipPath874)">
                  <g id="g894">
                    <g id="g892">
                      <g id="g890">
                        <g id="g888">
                          <path id="path886" style="fill:url(#radialGradient884);stroke:none"
                            d="m 167.695,175.451 c -17.323,-8.689 -14.663,-49.031 5.944,-90.116 20.601,-41.077 51.34,-67.34 68.661,-58.658 17.321,8.691 14.662,49.029 -5.939,90.114 -20.606,41.078 -51.348,67.346 -68.666,58.66" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g900">
        <g clip-path="url(#clipPath906)" id="g902">
          <g id="g908">
            <g id="g910" />
            <g mask="url(#mask952)" id="g980">
              <g id="g982" />
              <g id="g1010">
                <g id="g1008" clip-path="url(#clipPath986)">
                  <g id="g1006">
                    <g id="g1004">
                      <g id="g1002">
                        <g id="g1000">
                          <path id="path998" style="fill:url(#radialGradient996);stroke:none"
                            d="m 129.617,64.583 c -1.888,-18.36 31.88,-36.877 75.424,-41.354 43.543,-4.487 80.375,6.765 82.263,25.125 1.891,18.36 -31.878,36.877 -75.42,41.358 -43.544,4.479 -80.374,-6.771 -82.267,-25.129" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g1012">
        <g clip-path="url(#clipPath1018)" id="g1014">
          <g id="g1020">
            <g id="g1022" />
            <g mask="url(#mask1064)" id="g1092">
              <g id="g1094" />
              <g id="g1122">
                <g id="g1120" clip-path="url(#clipPath1098)">
                  <g id="g1118">
                    <g id="g1116">
                      <g id="g1114">
                        <g id="g1112">
                          <path id="path1110" style="fill:url(#radialGradient1108);stroke:none"
                            d="M 89.561,217.632 C 73.6,213.054 69.406,178.63 80.238,140.679 c 10.824,-37.875 32.599,-64.915 48.585,-60.377 15.96,4.584 20.157,39.003 9.323,76.953 -10.825,37.875 -32.596,64.917 -48.585,60.377" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g1124">
        <g clip-path="url(#clipPath1130)" id="g1126">
          <g id="g1132">
            <g id="g1134" />
            <g mask="url(#mask1176)" id="g1204">
              <g id="g1206" />
              <g id="g1234">
                <g id="g1232" clip-path="url(#clipPath1210)">
                  <g id="g1230">
                    <g id="g1228">
                      <g id="g1226">
                        <g id="g1224">
                          <path id="path1222" style="fill:url(#radialGradient1220);stroke:none"
                            d="m 139.577,154.923 c 42.004,-19.676 91.112,-3.501 109.683,36.143 18.574,39.636 -0.425,87.724 -42.432,107.404 -42.004,19.68 -91.113,3.5 -109.683,-36.139 -18.57,-39.638 0.426,-87.728 42.432,-107.408" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g1236">
            <g id="g1238" />
            <g mask="url(#mask1280)" id="g1308">
              <g id="g1310" />
              <g id="g1338">
                <g id="g1336" clip-path="url(#clipPath1314)">
                  <g id="g1334">
                    <g id="g1332">
                      <g id="g1330">
                        <g id="g1328">
                          <path id="path1326" style="fill:url(#radialGradient1324);stroke:none"
                            d="m 236.016,137.6 c 9.719,-1.868 24.302,31.46 32.576,74.447 8.273,42.983 7.103,79.346 -2.615,81.214 -9.715,1.873 -24.299,-31.458 -32.575,-74.443 -8.271,-42.983 -7.101,-79.346 2.614,-81.218" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g1340">
        <g clip-path="url(#clipPath1346)" id="g1342">
          <g id="g1348">
            <g id="g1350" />
            <g mask="url(#mask1392)" id="g1420">
              <g id="g1422" />
              <g id="g1450">
                <g id="g1448" clip-path="url(#clipPath1426)">
                  <g id="g1446">
                    <g id="g1444">
                      <g id="g1442">
                        <g id="g1440">
                          <path id="path1438" style="fill:url(#radialGradient1436);stroke:none"
                            d="m 165.835,146.261 c 49.476,-39.68 100.286,-58.507 113.489,-42.044 13.206,16.459 -16.203,61.976 -65.679,101.656 -49.478,39.685 -100.29,58.51 -113.49,42.047 -13.205,-16.459 16.201,-61.975 65.68,-101.659" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g1452">
            <g id="g1454" />
            <g mask="url(#mask1498)" id="g1526">
              <g id="g1528" />
              <g id="g1558">
                <g id="g1556" clip-path="url(#clipPath1532)">
                  <g id="g1554">
                    <g id="g1552">
                      <g id="g1550">
                        <g id="g1548">
                          <path id="path1546" style="fill:url(#radialGradient1544);stroke:none"
                            d="m 232.627,159.582 c 56.931,24.345 96.357,59.806 88.063,79.209 -8.294,19.403 -61.173,15.399 -118.104,-8.943 -56.932,-24.346 -96.358,-59.808 -88.063,-79.21 8.296,-19.402 61.174,-15.397 118.104,8.944" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g1560">
            <g id="g1562" />
            <g mask="url(#mask1604)" id="g1632">
              <g id="g1634" />
              <g id="g1662">
                <g id="g1660" clip-path="url(#clipPath1638)">
                  <g id="g1658">
                    <g id="g1656">
                      <g id="g1654">
                        <g id="g1652">
                          <path id="path1650" style="fill:url(#radialGradient1648);stroke:none"
                            d="m 252.034,196.758 c 24.996,-0.304 45.367,8.34 45.5,19.302 0.136,10.971 -20.016,20.112 -45.001,20.423 -24.997,0.307 -45.371,-8.341 -45.506,-19.302 -0.133,-10.967 20.013,-20.118 45.007,-20.423" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="g1664">
        <g clip-path="url(#clipPath1670)" id="g1666">
          <g id="g1672">
            <g id="g1674" />
            <g id="g1694">
              <g style="opacity:0.39999402" id="g1692" clip-path="url(#clipPath1678)">
                <g id="g1682" transform="translate(322.8307,199.163)">
                  <path id="path1680" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 0,0 -55.765,35.492 -0.716,-1.102 Z" />
                </g>
                <g id="g1686" transform="translate(322.7935,198.0385)">
                  <path id="path1684" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 0,0 51.604,29.693 -0.642,1.147 Z" />
                </g>
                <g id="g1690" transform="translate(321.8244,198.7206)">
                  <path id="path1688" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
                    d="M 0,0 -9.642,-62.185 1.293,-0.216 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g transform="translate(158.4262,169.3642)" id="g1696">
        <path id="path1698" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 -49.59,35.88 -1.585,-2.097 Z" />
      </g>
      <g transform="translate(158.2154,167.1346)" id="g1700">
        <path id="path1702" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 59.299,30.521 -1.162,2.361 Z" />
      </g>
      <g transform="translate(156.3194,168.3434)" id="g1704">
        <path id="path1706" style="fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none"
          d="M 0,0 0.117,-59.756 2.628,-0.055 Z" />
      </g>
      <g id="g1708">
        <g clip-path="url(#clipPath1714)" id="g1710">
          <g id="g1716">
            <g id="g1718" />
            <g id="g1786">
              <g style="opacity:0.19999701" id="g1784" clip-path="url(#clipPath1722)">
                <g id="g1762">
                  <g id="g1760">
                    <g id="g1758">
                      <g id="g1756">
                        <path id="path1754" style="fill:url(#radialGradient1732);stroke:none"
                          d="m 253.073,215.213 3.163,55.784 -4.474,-55.693 z" />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="g1772">
                  <g id="g1770">
                    <g id="g1768">
                      <g id="g1766">
                        <path id="path1764" style="fill:url(#radialGradient1742);stroke:none"
                          d="m 252.138,215.855 -35.712,-17.408 36.269,16.215 z" />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="g1782">
                  <g id="g1780">
                    <g id="g1778">
                      <g id="g1776">
                        <path id="path1774" style="fill:url(#radialGradient1752);stroke:none"
                          d="m 252.073,214.699 10.032,-5.426 -9.342,6.544 z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>