<template>
    <dialog ref="dialog" class="modal-root" @close="model = false">
        <slot v-for="template in shownTemplates" :name="template"></slot>
    </dialog>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch, defineModel } from 'vue';

const dialog = ref<HTMLDialogElement>();
const model = defineModel({
    default:false
})
const props = withDefaults(defineProps<{
    templates:string[],
    value:boolean
}>(),{
    templates:()=>(['default']),
    value:false
});
const manualTemplates = ref<string[]>([]);
const shownTemplates = computed(()=>props.templates.concat(manualTemplates.value));
function show(...templates:string[]){
    for(let template in templates){
        if(!manualTemplates.value.includes(template))
            manualTemplates.value.push(template);
    }
}
function showNew(...templates:string[]){
    manualTemplates.value = templates;
}
function hide(...templates:string[]){
    for(let template in templates){
        const index = manualTemplates.value.indexOf(template)
        if(index>=0) manualTemplates.value.splice(index,1);
    }
}
function hideAll(){
    manualTemplates.value = [];
}
function open(){
    model.value = true;
    dialog.value?.showModal();
}
function close(returnValue?: string){
    model.value = false;
    dialog.value?.close(returnValue);
}
if(props.value) onMounted(()=>show());
watch(()=>props.value, value=>{
    if(value) open();
    else close();
})
defineExpose({
    open,
    close,
    show,
    showNew,
    hide,
    hideAll,
    get returnValue(){ return dialog.value?.returnValue }
})
</script>
<style scoped lang="scss">
.modal-root{
    $transition: 0.15s ease-in-out;
    display: flex;
    border: none;
    border-radius: 8px;
    transition: $transition;
    &::backdrop{
        background: rgba(224, 224, 224, 0.5);
    }
    &:not([open]){
        opacity: 0;
        transform: scale(0.95);
        transition: none;
    }
}
</style>