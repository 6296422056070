export default {
    /**
     *
     * @param {import("vue").App} app
     */
    install(app){
        function outsideElement(element, other){
            if(other == element) return false;
            return !element.contains(other);
        }
        app.config.globalProperties.$outsideElement = outsideElement;
        app.directive("outside", {
            created(el,binding){
                let ctxHandler = binding.value;
                let ctxEl = el;
                let ctxEvent = binding.arg;
                const listener = (e)=>{
                    if(outsideElement(ctxEl,e.target)) ctxHandler?.(e);
                };
                const removeHandler = ()=>{
                    document.removeEventListener(ctxEvent,listener);
                    ctxHandler = undefined;
                }
                const setHandler = (el,event,handler, options)=>{
                    removeHandler();
                    ctxEl = el;
                    ctxHandler = handler;
                    ctxEvent = event;
                    document.addEventListener(ctxEvent, listener, options);
                }
                binding.instance.removeHandler = removeHandler;
                binding.instance.setHandler = setHandler;
            },
            beforeMount(el, binding){
                binding.instance.setHandler(el, binding.arg, binding.value, binding.modifiers);
            },
            beforeUpdate(el, binding){
                binding.instance.setHandler(el, binding.arg, binding.value, binding.modifiers);
            },
            beforeUnmount(el,binding){
                binding.instance.removeHandler(el, binding.arg, binding.value, binding.modifiers);
            }
        })
    }
}