import { Paragraph } from "./Service";

export class ParagraphType{
    name:string;
    tag:string;
    defaultChildType:ParagraphType;
    /**
     * @param {Object} param
     * @param {String} param.name
     * @param {String} param.tag
     * @param {ParagraphType} param.defaultChildType
     */
    constructor({ name, tag, defaultChildType = ParagraphType.default }:{ name:string, tag:string, defaultChildType?:ParagraphType }){
        this.name = name;
        this.tag = tag;
        this.defaultChildType = defaultChildType ?? this
    }
    /**
     *
     * @param {String} typeName
     * @returns {ParagraphType}
     */
    static from(typeName:string):ParagraphType {
        return Object.values(this).find(value=>value instanceof ParagraphType && value.name == typeName);
    }
    static default:ParagraphType;
    static paragraph:ParagraphType;
    static text:ParagraphType;
    static listItem:ParagraphType;
    static list:ParagraphType;
    static orderedList:ParagraphType;
}
ParagraphType.paragraph = new ParagraphType({ name:"paragraph", tag:"p" });
Object.defineProperty(ParagraphType,"default",{ enumerable:true, value:ParagraphType.paragraph });
ParagraphType.text = new ParagraphType({ name:"text", tag:"span" });
ParagraphType.listItem = new ParagraphType({ name:"listItem", tag:"li" });
ParagraphType.list = new ParagraphType({ name:"list", tag:"ul", defaultChildType:ParagraphType.listItem });
ParagraphType.orderedList = new ParagraphType({ name:"list:order", tag:"ol", defaultChildType:ParagraphType.listItem });

export default class ServiceParagraph{
    type?:string;
    text:string;
    tag?:string;
    children:ServiceParagraph[]
    constructor({ type, text = "", children = [], tag }:{ type:string, text?:string, children?:ServiceParagraph[], tag?:string }){
        this.type = type;
        this.text = text;
        this.tag = tag ?? ParagraphType.from(type).tag;
        this.children = children;
    }
    /**
     *
     * @param { String | Array<String | Object> | Object } sorce
     * @param { ServiceParagraph | undefined } parentType
     * @returns { Array<ServiceParagraph> | ServiceParagraph }
     */
    static from(sorce:Paragraph | Paragraph[], parentType?:ParagraphType):ServiceParagraph[] | ServiceParagraph {
        const getDefaultType = (localDefault:ParagraphType)=> {
            return parentType?.defaultChildType ?? localDefault ?? parentType;
        }
        if(typeof sorce == "string") {
            const splittedText = sorce.split("\n") ;
            if(splittedText.length>1) //если строка была разделена, значит она не обработана.
                return this.from(splittedText, ParagraphType.paragraph);//обрабатываем, как массив "параграфов".
            return new ServiceParagraph({
                type: getDefaultType(ParagraphType.text).name,
                text: splittedText[0]
            });
        }
        if(Array.isArray(sorce)) return sorce.map(p=>this.from(p,parentType) as ServiceParagraph);
        const sourceChildren = sorce.children ?? [];
        return new ServiceParagraph({
            type: sorce.type ?? getDefaultType(ParagraphType.text).name,
            text: sorce.text?.toString(),
            tag: sorce.tag,
            children: this.from(Array.isArray(sourceChildren)?sourceChildren:[sourceChildren],ParagraphType.from(sorce.type?.toString() ?? "")) as ServiceParagraph[]
        });
            // Array.isArray(props.text)?props.text:[props.text]
    }
}
// window.SP = ServiceParagraph;