<template>
  <div class="carousel" @mouseenter="autoscrollDisabled = true" @mouseleave="autoscrollDisabled = false">
		<div class="arrow l" :class="[arrowLeftClass, { active:(Array.isArray(arrows)?arrows[0]:arrows) && arrowsState[0] }]" @click="slide(-1)">
			<slot name="arrow-left">
				<SvgDefaultArrow class="default-arrow" style="transform:rotate(180deg)"></SvgDefaultArrow>
			</slot>
		</div>
		<div class="arrow r" :class="[arrowRightClass, { active:(Array.isArray(arrows)?arrows[1]:arrows) && arrowsState[1] }]" @click="slide(1)">
			<slot name="arrow-right">
				<SvgDefaultArrow class="default-arrow"></SvgDefaultArrow>
			</slot>
		</div>
		<div ref="items" class="items" @scroll="onscroll" :style="{ padding }">
			<div class="item" :class="itemsClass" v-for="item,index in items" :key="item.id" >
				<slot :item="item" :width="alignedWidth" :index="index"></slot>
			</div>
		</div>
  </div>
</template>

<script>
import SvgDefaultArrow from './svg-default-arrow.vue'
import HScroller from './scroller.js';
export default {
	name:"VCarousel",
	components:{
		SvgDefaultArrow
	},
	props:{
		id:{ default:0 },
		items:{
			type:[Array,Number],
			default:()=>[],
			required:true
		},
		itemWidth:{
			type:Number,
			default:100
		},
		arrows:{
			type:[Boolean,Number,Array],
			default:false
		},
		align:{
			type:[Boolean,Number],
			default:false
		},
		itemsClass:{ default:"" },
		arrowLeftClass:{ default:"" },
		arrowRightClass:{ default:"" },
		autoScroll:{ default:false },
		scrollTimeInterval:{
			type:Number,
			default:2000
		},
		padding:{
			type:String,
			default:"0px"
		}
	},
	data(){
		return{
			scrollLeft:0,
			current:0,
			accumulator:-1,
			scroller:null,
			scrolling:false,
			autoscrollDisabled:false,
			// alignedWidth:this.align?this.getStoredWidth() || this.itemWidth:this.itemWidth,
			sliderWidth:0
		}
	},
	watch:{
		current(){ this.$emit("change",{ index:this.current }); },
		autoScroll(){ this.updateAutoscroll() },
		autoscrollDisabled(){ this.updateAutoscroll() },
	},
	computed:{
		viewportItemsCount(){
			let count = Math.round(this.sliderWidth / this.itemWidth);
			this.$emit("aligned",{ count });
			return count;
		},
		alignedWidth(){
			return this.sliderWidth/this.viewportItemsCount;
		},
		arrowsState(){
			return [this.current!=0, this.items.length > this.viewportItemsCount && this.current + this.viewportItemsCount < this.items.length];
		}
	},
	mounted(){
		this.scroller = new HScroller(this.$refs.items);
		this.resizeObserver = new ResizeObserver(this.update);
		this.resizeObserver.observe(this.$refs.items);
		this.update();
		this.updateAutoscroll();
	},
	unmounted(){
		this.resizeObserver?.disconnect();
	},
	methods:{
		update(){
			this.sliderWidth = this.$refs.items.getBoundingClientRect().width;
		},
		// updateArrows(){
		// 	this.arrowsState[0] = this.current==0;
		// 	let width = this.$refs.items.getBoundingClientRect().width;
		// 	this.arrowsState[1] = this.items<=width/this.itemWidth;
		// },
		updateAutoscroll(){
			if(this.autoScroll && !this.autoscrollDisabled)
				this.autoScrollIntervalId = this.startAutoscroll(Number(this.scrollTimeInterval) || 2000);
			else clearInterval(this.autoScrollIntervalId);
		},
		startAutoscroll(interval){
			return setInterval(()=>{
				if(this.current+1>this.items.length){
					this.slide(-this.items.length+1)
					return;
				}
				this.slide(1);
			},interval)
		},
		// initAlign(){
		// 	let update = ()=>{
		// 		// let width = this.$refs.items.getBoundingClientRect().width;
		// 		// console.log(`id:${this.id},${width}/Math.round(${width}/${this.itemWidth})`)
		// 		// this.alignedWidth = this.sliderWidth/Math.round(this.sliderWidth/this.itemWidth);
		// 		this.storeWidth();//save width to use before slider will loaded next time
		// 	}
		// 	update();
		// 	["resize","orientationchanged"].forEach(e=>window.addEventListener(e,update));
		// 	this.$watch(()=>this.items,update);
		// },
		onscroll(){
			this.current = Math.round(this.$refs.items.scrollLeft/this.itemWidth);
		},
		slide(direction){
			direction = Math.round(direction);
			if(!direction) throw new Error(`property "direction" can not be ${direction}!`);
			this.accumulator = this.scrolling?this.accumulator+direction:this.current;
			this.scrolling = true;
			let target = (this.accumulator+direction)*this.alignedWidth;
			this.scroller.scroll(target,300,5)?.then(()=>{ this.scrolling=false; });
		},
		storeWidth(){
			localStorage[`carousel_${this.id}`] = this.alignedWidth;
		},
		getStoredWidth(){
			return Number(localStorage[`carousel_${this.id}`]) || 0;
		}
	}
}
</script>

<style lang="scss">
.carousel{
	position: relative;
	display: flex;
	overflow: hidden;
	&>.arrow{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		transition: opacity 0.2s cubic-bezier(0.55, 0.09, 0.68, 0.53);
		user-select: none;
		cursor: pointer;
		&.l{ left: 3px; }
		&.r{ right: 3px; }
		&:not(.active){
			opacity: 0;
			pointer-events: none;
		}
		.default-arrow{
			fill: white;
			stroke: black;
			width: 20px;
		}
	}
	.items{
		position: relative;
		// background: yellowgreen;
		display: flex;
		overflow-x: auto;
		scrollbar-width: none;
		min-width: 100%;
		&::-webkit-scrollbar{
			display: none;
		}
	}
}
</style>