type NamedFieldsetObject = Record<string, NamedField<any>>;
type WrappedNamedFieldSet<T extends NamedFieldsetObject> = { [K in keyof T]:T[K]['value'] };
export class NamedFieldSet<T extends NamedFieldsetObject>{
    private _obj:T;
    constructor(obj:T){
        this._obj = obj;
    }
    nameOf(property:keyof T){ return this._obj[property]?.name }
    namedFieldOf(property:keyof T){ return this._obj[property] }
    getValueOf(property: number | string | symbol){
        return (property in this._obj) && ('value' in this._obj[property as keyof T])?this._obj[property as keyof T]?.value:this[property as keyof typeof this];
    }
    set(property:number | string | symbol, value:any) {
        return (property in this._obj) && ('value' in this._obj[property as keyof T])?this._obj[property as keyof T].value = value:this[property as keyof typeof this] = value;
    }
    static wrap<T extends Record<string, NamedField<any>>>(obj:T):{ [K in keyof T]:T[K]['value'] }{
        return new Proxy(new NamedFieldSet(obj),{
            get(target,p){ return target.getValueOf(p); },
            set(target,p,newValue){
                target.set(p as keyof T,newValue);
                return true;
            },
            defineProperty(...args){
                Object.defineProperty(...args);
                return true;
            },
            getOwnPropertyDescriptor(...args){ return Object.getOwnPropertyDescriptor(...args) },
            isExtensible(...args){ return Object.isExtensible(...args) },
            // getOwnPropertyDescriptors(...args){ Object.getOwnPropertyDescriptors(...args) },
        }) as WrappedNamedFieldSet<T>;
    }
    toString(){
        return Object.values(this._obj).join("\r\n");
    }
}
export default class NamedField<T> {
    name:string;
    value?:T
    constructor(name:string, value?:T, toString = (name:string, value?:T)=>`${name}: ${value}`){
        if(typeof name != "string")
            throw new TypeError(`Unexpected parameter type ${typeof name}. string expected.`)
        this.name = name;
        this.value = value;
        if(toString != this.toString) this.toString = ()=>toString?.(this.name,this.value);
    }
    toString(){
        return `${this.name}: ${this.value}`
    }
}