<template>
  <router-view />
</template>

<style lang="scss">
#app {
  height: 100%;
  display: flex;
}
</style>
