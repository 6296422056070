export default class HScroller{
  constructor(element,direction = 'scrollLeft'){
    this.element = element;
    this.direction = direction;
    this._duration = 0;
    this._target = element[this.direction];
    this._scrolling = false;
  }
  scroll(to,duration,rate=100){
    this._from = this.element[this.direction];
    // console.log("this._from",this._from);
    if(this._from == to) return;
    this._target = to-this._from;
    // console.log("_from",this._from,"_target",this._target,"=",to,"-",this._from);
    this._duration = duration;
    this._startTime = Date.now();
    if(this._scrolling) return;
    this._scrolling = true;
    return new Promise((resolve)=>{
      const intervalToken = setInterval(()=>{
        const now = Date.now();
        const passed = now-this._startTime;
        const value = passed/this._duration;
        // console.log(Math.min(value,1),this._from, this._target,1);
        this.element[this.direction] = this.easeInOutQuad(Math.min(value,1),this._from, this._target,1);
        if(value>=1){
          clearInterval(intervalToken);
          this._duration = 0;
          this._target = this.element[this.direction];
          this._scrolling = false;
          resolve();
        }
      },rate);
    })
  }
  easeInOutQuad (t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
  }
}