<template>
    <div :class="['expander',directionClass,{ expanded:value, animation }]"
        :style="cssVars" :v-bind="$attrs" @transitionend="transitionend">
        <div ref="sizeDetector"><slot></slot></div>
    </div>
</template>

<script>
const allDirections = ['row','column'];
export default {
    props:{
        value:{ default:true },
        transition:{ default:'all 0.3s ease-in-out' },
        direction:{ default:'height' },
        origin:{ default:'top' },
        minSize:{ default:'min-content' },
        opacity:{ default:1 },
        alwaysEnabled:{ default:false }
    },
    watch:{
        value(){
            this.transitionEnabled = true;
            this.animation = true;
        }
    },
    data(){
        return {
            width:0,
            height:0,
            transitionEnabled:false,
            animation:false
        }
    },
    mounted(){
        this.resizeObserver = new ResizeObserver(this.onResize);
        this.resizeObserver.observe(this.$refs.sizeDetector);
        // this.updateSize();
    },
    beforeUnmount(){
        this.resizeObserver.disconnect();
    },
    computed:{
        directionClass(){
            if(this.direction=='all') return allDirections;
            switch(this.direction){
                case 'width': case 'horizontal': case 'x': case 'row': return 'row';
                case 'height': case 'vartical': case 'y': case 'column': return 'column';
                default: return 'column';
            }
        },
        computedDirection(){
            return this.directionClass == allDirections?'column':this.directionClass;
        },
        cssVars(){
            return {
                '--expander-transition':this.transitionEnabled || this.alwaysEnabled?this.transition:'',
                '--expander-direction':this.computedDirection,
                '--expander-expanded':this.value,
                '--expander-origin':this.origin,
                '--expander-width':this.width+'px',
                '--expander-height':this.height+'px',
                '--expander-min-size':this.minSize,
                '--expander-opacity':this.opacity
            }
        }
    },
    methods:{
        /** @type { ResizeObserverCallback } */
        onResize(){
            if(this.alwaysEnabled || this.value) this.animation = true;
            this.updateSize()
        },
        updateSize(){
            const rect = this.$refs.sizeDetector.getBoundingClientRect()
            this.width = rect.width;
            this.height = rect.height;
        },
        transitionend(){
            this.transitionEnabled = false;
            this.animation = false;
        }
    }
}
</script>

<style lang="scss">
.expander{
    transition: var(--expander-transition);
    transform-origin: var(--expander-origin);
    &:not(.expanded), &.expanded.animation { overflow: hidden; }
    &>div{
        display: flex;
        flex-direction: var(--expander-direction);
    }
    &.row{
        width: var(--expander-width);
        &:not(.expanded){ width:0px; opacity: var(--expander-opacity); }
        &>div{ min-width: var(--expander-min-size); }
    }
    &.column{
        height: var(--expander-height);
        &:not(.expanded){ height:0px; opacity: var(--expander-opacity); }
        &>div{ min-height: var(--expander-min-size); }
    }
}
</style>