<template>
    <svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 5307.2266 3961.9067"
        height="3961.9067"
        width="5307.2266"
        xml:space="preserve"
        id="svg2"
        fill="#231f20"
        fill-opacity="1"
        fill-rule="nonzero"
        stroke="none"
        version="1.1"><g
            transform="matrix(1.3333333,0,0,-1.3333333,0,3961.9067)"
            id="g10"><g
            transform="scale(0.1)"
            id="g12"><g
                transform="scale(2.12338)"
                id="g14"><path
                id="path16"
                d="m 8168.91,13993.9 c 32.02,-18.6 62.45,-41.2 96.4,-55.1 2648.79,-1087.6 5297.79,-2174.7 7947.09,-3261.1 52.1,-21.4 87.6,-38.5 87.4,-111 -3.5,-978.08 -2.3,-1956.19 -2.3,-2934.34 0,-24.59 0,-49.22 0,-78.56 -205.9,0 -402.2,0 -612,0 0,37.35 0,74.23 0,111.1 0,834.75 -1.2,1669.5 2,2504.2 0.3,73 -22.9,104.7 -88,131.3 -2452.3,1004.9 -4903.7,2011.9 -7356.61,3015.3 -51.71,21.1 -127.15,21.5 -178.77,0.4 -2446.99,-1001 -4892.4,-2005.9 -7339.251,-3007.3 -83.369,-34.1 -112.077,-72.2 -112.001,-163.4 2.654,-3284.78 2.228,-6569.64 2.228,-9854.448 0,-41.115 0,-82.229 0,-130.171 -204.209,0 -402.866,0 -613.0448,0 0,34.898 0,68.806 0,102.714 C 2.0512,3688.51 2.38233,7113.52 0,10538.5 c -0.0551891,79.8 25.8543,113.5 97.1384,142.7 C 2740.89,11764.3 5383.82,12849.4 8026.54,13935 c 36.59,15 69.47,39 104.08,58.9 12.76,0 25.53,0 38.29,0" /></g><g
                transform="scale(2.44196)"
                id="g18"><path
                id="path20"
                d="m 12837,3854.48 c 177.3,0 354.6,-1.15 531.8,0.45 101.9,0.94 154,52.42 154.3,153.77 1.2,354.39 1.1,708.82 0.2,1063.2 -0.3,106.47 -51.4,159.1 -158.7,159.59 -351.8,1.55 -703.6,1.47 -1055.4,0.12 -110.7,-0.41 -163.9,-53.69 -164.3,-163.84 -1.3,-348.86 -1.2,-697.72 -0.1,-1046.58 0.3,-115.93 52,-165.89 168.7,-166.5 174.5,-0.95 349,-0.25 523.5,-0.21 z m 3463.1,-2574.57 c -48.7,-86.32 -122.6,-114.7 -220.6,-113.64 -338,3.77 -676.1,2.58 -1014.1,0.33 -54.3,-0.37 -73.9,14.54 -85.7,70.19 -110.5,519.5 -547.6,876.14 -1065.4,874.18 -518.1,-2.01 -946.9,-359.88 -1060.6,-881.79 -5.1,-23.51 -37.8,-58.57 -57.9,-58.73 -395.9,-3.48 -791.8,-2.58 -1194.5,-2.58 0,1535.2 0,3064.71 0,4601.95 25.7,1.81 52.2,5.33 78.7,5.33 759.3,0.28 1518.5,-0.7 2277.7,1.84 65.5,0.21 90.1,-23.55 109.1,-82.11 210.2,-646.81 424,-1292.44 633.4,-1939.5 19.7,-60.89 45,-82.76 109.2,-80.3 132.8,5.16 266.1,-2.87 398.8,2.9 120.8,5.25 222.9,-35.38 315.5,-106.06 177.4,-135.34 312,-306.56 403.8,-508.81 25.3,-55.82 36.7,-122.48 37.3,-184.32 3.4,-360 1.7,-720.08 1.7,-1080.16 0,-30.09 0,-60.19 0,-98.36 122.5,-15.6 269.9,37.8 333.6,-121.17 0,-99.72 0,-199.47 0,-299.19" /></g><g
                transform="scale(1.63531)"
                id="g22"><path
                id="path24"
                d="m 14699.2,2629.1 c 442.5,0 885.1,0.55 1327.6,-0.18 197.2,-0.37 271.7,-73.38 272.2,-266.68 0.9,-314.19 1,-628.38 0,-942.57 -0.6,-193.42 -71.6,-265.03 -273.9,-266.19 -434.3,-2.45 -868.6,-0.86 -1302.8,-0.86 -442.6,0 -885.1,-0.67 -1327.6,0.31 -240.6,0.49 -304.1,64.15 -304.2,299.21 -0.2,305.93 -0.7,611.87 0.2,917.74 0.5,178.5 77.8,258.12 256,258.73 450.8,1.47 901.7,0.49 1352.5,0.49" /></g><path
                id="path26"
                d="m 10140.9,14112.7 c 771.3,0 1542.6,0.5 2313.9,-0.3 255.6,-0.3 280.6,-24.6 281.1,-275.9 1.1,-608.7 1.5,-1217.3 -0.3,-1825.9 -0.7,-238.5 -31.9,-268.6 -270.2,-268.7 -1556.1,-0.6 -3112.21,2 -4668.29,-3.2 -193.03,-0.7 -264.5,65.9 -262.13,259.6 7.63,622.1 6.98,1244.3 0.32,1866.4 -1.97,184.4 68.26,251.6 251.21,250.3 784.77,-5.6 1569.61,-2.4 2354.39,-2.3" /><path
                id="path28"
                d="m 10137.5,8091.5 c -771.29,0 -1542.58,-0.4 -2313.88,0.3 -264.46,0.3 -285.3,21.7 -285.63,291.8 -0.75,601.9 -1,1203.8 0.15,1805.7 0.48,247.1 24.95,272.8 262.45,273 1556.13,0.7 3112.21,0.6 4668.41,0 240.5,-0.1 266.3,-26 266.8,-268.7 1.3,-608.7 1.4,-1217.3 -0.1,-1826 -0.6,-248.5 -29.4,-275.5 -284.3,-275.8 -771.3,-0.8 -1542.6,-0.3 -2313.9,-0.3" /><g
                transform="scale(1.72328)"
                id="g30"><path
                id="path32"
                d="m 12309.4,1654.75 c -175.5,0 -339.3,-0.06 -503,0 -168.8,0 -337.8,-3.72 -506.3,3.13 -34.1,1.39 -88.9,36.56 -96.7,66.1 -192.3,725.94 -559,1087.17 -1307.52,1261.89 2148.02,0 4272.22,0 6404.12,0 0,-440.61 0,-877.63 0,-1322.94 -228.6,0 -454.1,0 -697.6,0 0,171.19 0,342.02 0,512.8 0,58.9 1.3,117.74 -0.4,176.58 -4.5,164.28 -91.5,253.82 -257.6,255.56 -274.7,2.9 -549.5,0.87 -824.3,0.87 -635.9,0 -1271.8,0.23 -1907.7,-0.11 -224.9,-0.18 -302.4,-78.86 -302.9,-306.22 -0.5,-211.05 -0.1,-422.04 -0.1,-647.66" /></g><g
                transform="scale(1.16666)"
                id="g34"><path
                id="path36"
                d="m 14066.6,10627.2 c 666.4,0 1332.6,0.5 1999,-0.3 212.5,-0.3 228.9,-17.1 229.1,-226 0.6,-526.92 -4.5,-1054.07 3,-1580.95 2.3,-166.55 -57.3,-224.23 -222.6,-223.72 -1338.5,4.11 -2676.9,4.11 -4015.2,0 -164.8,-0.51 -225.7,55.63 -223.4,222.94 7.5,532.72 7.4,1065.61 0.1,1598.33 -2.2,160.8 57.8,213 213.8,211.8 672,-5.1 1344.1,-2.1 2016.2,-2.1" /></g><g
                transform="scale(1.56054)"
                id="g38"><path
                id="path40"
                d="m 14635.7,7944.88 c 498.8,0 997.6,-2.63 1496.3,1.86 123,1.08 168,-39.09 165.8,-165.72 -6.6,-394.41 -5.7,-789.02 -0.4,-1183.5 1.6,-120.85 -34.4,-171.54 -162.3,-171.09 -1001.9,3.33 -2003.8,3.27 -3005.7,0.06 -124.3,-0.38 -168.5,44.09 -166.9,167.96 5.2,394.48 1.5,789.02 2.1,1183.56 0.3,152.77 14.6,166.48 174.7,166.68 498.8,0.51 997.6,0.19 1496.4,0.19" /></g><g
                transform="scale(1.16666)"
                id="g42"><path
                id="path44"
                d="m 14067.2,5245.22 c -666.3,0 -1332.6,-0.52 -1998.9,0.25 -213.9,0.26 -228.5,15.26 -228.8,226.29 -0.6,527.06 4.5,1054.12 -3,1581.01 -2.3,167.05 56.8,222.25 222.7,221.66 1338.4,-4.72 2676.9,-4.72 4015.3,0 165.4,0.51 225.5,-53.83 223.2,-221.24 -7.5,-532.71 -7.1,-1065.68 -0.2,-1598.4 2.1,-160.11 -57.4,-213.09 -213.9,-211.8 -672.1,5.31 -1344.3,2.23 -2016.4,2.23" /></g><g
                transform="scale(1.56044)"
                id="g46"><path
                id="path48"
                d="m 14622.5,3921.6 c -494.6,0 -989.1,-0.39 -1483.5,0.19 -159.9,0.19 -173.3,13.33 -173.5,168.29 -0.6,394.57 3.2,789.13 -2.1,1183.64 -1.8,125.61 45.1,165.4 168.4,165.02 1001.9,-3.46 2004,-3.27 3006,-0.26 117,0.39 162.2,-37.49 160.7,-157.78 -5.2,-403.15 -4.9,-806.44 -0.2,-1209.59 1.4,-114.84 -42.8,-151.69 -153.3,-150.92 -507.5,3.33 -1015.1,1.47 -1522.5,1.41" /></g><path
                id="path50"
                d="m 12428.4,2394.8 c 2.1,823.2 -668.4,1498.1 -1491.6,1501.4 -822.8,3.3 -1500.08,-664.8 -1507.41,-1487 -7.4,-829.6 672.21,-1514.298 1501.81,-1513.102 820.1,1.203 1495.1,676.902 1497.2,1498.702 z m -3895.32,0.1 C 8533.05,3718.1 9612.91,4793.1 10938.4,4789.5 12248.7,4786 13323.3,3707.3 13324.8,2394 13326.2,1072.9 12244,-3.80078 10918.4,0 9605.59,3.89844 8533.1,1080.6 8533.08,2394.9" /><g
                transform="scale(1.17144)"
                id="g52"><path
                id="path54"
                d="m 15534.7,2046.28 c -1.6,704.86 -577,1278.51 -1281.1,1277.32 -702.3,-1.11 -1280.4,-580.57 -1278.7,-1281.58 1.7,-701.53 581.3,-1278.088 1283.7,-1277.067 705.5,1.114 1277.7,575.617 1276.1,1281.327 z m 764.6,-4.26 C 16298.6,910.93 15377.6,-4.26824 14244.7,0 c -1120.1,4.26824 -2036.3,925.696 -2035.3,2046.88 1,1127.75 926.2,2045.85 2057.5,2041.76 1122.3,-4.1 2033.1,-921.26 2032.4,-2046.62" /></g><g
                transform="scale(2.23261)"
                id="g56"><path
                id="path58"
                d="m 14553.6,1074.53 c -0.7,-371.049 298.5,-672.399 668.3,-673.162 368.9,-0.761 672.3,300.771 673.9,669.582 1.5,367.59 -301.7,671.94 -670.4,672.93 -369.9,1.03 -671.2,-299.11 -671.8,-669.35 z m -401.5,1.83 c 1.3,593.61 484.5,1071.98 1079.7,1068.89 587.1,-3 1068.2,-488.67 1066.1,-1076.14 C 16295.9,477.647 15809,-3.17909 15215.5,0.0454905 14626.8,3.22458 14150.8,484.994 14152.1,1076.36" /></g><path
                id="path60"
                d="M 10532.7,5143 C 9798.87,5000.1 9180.56,4656.1 8772.76,4025 c -197,-304.9 -306.7,-668.4 -437.64,-1012.4 -37.66,-98.9 -51.48,-163.8 -178.2,-163.4 -1250.91,4.3 -2501.84,2.9 -3752.76,3.8 -25.82,0 -51.64,7.7 -89.96,13.8 0,758.3 0,1511.2 0,2276.2 2075.09,0 4146.8,0 6218.5,0" /><g
                transform="scale(1.16645)"
                id="g62"><path
                id="path64"
                d="m 14062.1,13122.5 c -661.5,0 -1322.9,-0.1 -1984.3,0 -243.7,0.1 -243.7,0.3 -243.8,251.5 -0.1,197.1 4.8,394.5 -1.9,591.4 -3.9,115.9 30.2,167.9 155.4,167.7 1381,-2.8 2761.8,-2.5 4142.7,-0.4 119.5,0.3 165.5,-43.2 161.9,-164.1 -6.7,-220.2 -9.5,-441 0.8,-660.9 7.1,-150.2 -55.7,-188.3 -194.4,-187.3 -678.8,4.7 -1357.6,2.1 -2036.4,2.1" /></g><g
                transform="scale(1.5558)"
                id="g66"><path
                id="path68"
                d="m 14621.6,9838.56 c -504,0 -1008.1,3.53 -1512.1,-2.51 -126.8,-1.54 -168,39.59 -160.1,164.55 9.8,155.7 6,312.6 0.9,468.8 -2.8,86.9 22.7,126.7 116.2,126.6 1038.5,-1.9 2077.1,-1.6 3115.6,-0.3 84,0.2 114.8,-31.2 112.2,-115.9 -5,-169.3 -7.2,-339 0.8,-508.07 4.9,-105.54 -35.3,-135.3 -135.3,-134.65 -512.7,3.21 -1025.4,1.48 -1538.2,1.48" /></g><g
                transform="scale(1.00146)"
                id="g70"><path
                id="path72"
                d="m 16300,5139.5 c -1008.4,-175.75 -1998,-902.19 -2249.5,-2290.86 -140.2,0 -286.6,-6.49 -431.8,4.6 -37.2,2.79 -93.6,56.41 -101.7,94.96 -255.4,1215.92 -1016.9,1916.9 -2245.1,2191.3 1699.6,0 3363.8,0 5028.1,0" /></g></g></g>
    </svg>
</template>
<script setup>
</script>