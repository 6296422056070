<template>
  <PageLayout>
    <template #header>
      <div class="header">
        <img width="210" src="../../../assets/full_logo.png" alt="Конструктор">
        <nav>
          <div>
            <a href="#" mark>О нас</a>
          </div>
          <div>
            <a href="#services-section" mark>Наша продукция</a>
          </div>
          <div>
            <a href="#gallery" mark>Наши работы</a>
          </div>
          <div>
            <a href="#contacts" mark>Контакты </a><br>
            <a href="tel:+79495097000" style="font-size: large;" mark><b>+7 (949) 509-70-00</b></a>
          </div>
        </nav>
      </div>
    </template>
    <section id="first-impression" stack>
      <div class="content">
        <h1 class="title" style="text-transform: uppercase">
          <span mark>Производство</span> пластиковых окон<br>
          и пвх-конструкций <span mark>любой сложности</span><br>
          под ключ
        </h1>
        <div id="arguments-grid">
          <div class="argument">
            <svg-argument1 />
            Материалы от проверенных поставщиков
          </div>
          <div class="argument">
            <svg-argument2 />
            Демократичная ценовая политика
          </div>
          <div class="argument">
            <svg-argument3 />
            Кратчайший срок изготовления. До&nbsp;10-и дней
          </div>
          <div class="argument">
            <svg-argument4 />
            Гарантия качества
          </div>
        </div>
      </div>
      <div class="background-image" stack>
        <img alt="background" src="../../../assets/windows.jpg" />
        <img class="ficus" alt="background" src="../../../assets/ficus.png" />
        <div class="front-shadow">
          <div class="background"></div>
          <div class="shadow"></div>
        </div>
      </div>
    </section>
    <section id="services-section">
      <div stack>
        <div class="content">
          <h1 class="title">НАША ПРОДУКЦИЯ</h1>
          <div class="services">
            <div style="position: relative;" v-for="service in services" :key="service.id">
              <ServiceBlock
                :image="service.circleImage ?? ''"
                :title="service.title ?? ''"
                :text="service.text ?? ''"
                :smallText="service.smallText ?? ''"
                :alt="service.alt ?? 'service'" />
            </div>
          </div>
        </div>
        <div class="background-image">
          <div class="cubes">
            <svg-cubes />
          </div>
          <div stretch="width"></div>
          <div class="cubes">
            <svg-cubes />
          </div>
        </div>
      </div>
    </section>
    <section id="gallery">
      <div class="content">
        <h1 class="title" style="text-transform: uppercase">
          наши работы
        </h1>
        <v-carousel :items="gallery" :item-width="300" :align="true" :arrows="true" #default="{ index, item, width }" padding="20px">
          <div class="gallery-image" @click="selectGalleryImage(index)">
            <img
              loading="lazy"
              :style="{ height:'200px', width:width+'px', 'text-align':'center', 'object-fit':'contain' }"
              :src="item.image" :alt="item.alt || 'ФОТО'" />
          </div>
        </v-carousel>
        <div>
        <ModalDialog :value="!!selectedGalleryImage" @close="selectGalleryImage(-1)">
          <div class="gallery-modal-image" v-outside:mousedown="()=>selectedGalleryImage?selectGalleryImage(-1):null">
            <SvgDefaultArrow :disabled="!selectedGalleryImageIndex" class="default-arrow" flip-x @click="selectGalleryImage(selectedGalleryImageIndex-1)"/>
            <img :src="selectedGalleryImage?.image" alt="Изображение галереи"/>
            <div class="counter">{{ selectedGalleryImageIndex+1 }}/{{ gallery.length }}</div>
            <SvgDefaultArrow :disabled="selectedGalleryImageIndex>=gallery.length-1" class="default-arrow" @click="selectGalleryImage(selectedGalleryImageIndex+1)"/>
          </div>
        </ModalDialog>
      </div>
      </div>
    </section>
    <section id="contacts">
      <div class="content">
        <h1 class="title" style="text-transform: uppercase">Мы находимся</h1>
        <div flex>
          <div itemscope itemtype="http://schema.org/Organization" style="flex: 1;">
            <ListTile>
              <template #leading><img src="../../../assets/icons/contacts/address.svg" width="32" height="32" alt="Адрес"/></template>
              <span  itemprop="address">Российская Федерация, Донецкая Народная Республика, г. Макеевка, ул.Успенского, д. 1А</span>
            </ListTile>
            <ListTile>
              <template #leading><img src="../../../assets/icons/contacts/mail.svg" width="32" height="32" alt="Mail"/></template>
              <a itemprop="email" href="mailto:sales@konstruktor-don.ru" mark>sales@konstruktor-don.ru</a>
            </ListTile>
            <ListTile>
              <template #leading><img src="../../../assets/icons/contacts/phone.svg" width="32" height="32" alt="Телефон" /></template>
              <a itemprop="telephone" href="tel:+79495097000" mark>+7 (949) 509-70-00</a>
            </ListTile>
            <ListTile>
              <h4>Мы в соцсетях:</h4>
              <img src="../../../assets/icons/socials/telegram.svg" alt="Telegram" width="32" height="32"/>
              <img src="../../../assets/icons/socials/vk.svg" alt="VK" width="32" height="32"/>
              <!-- <img src="../../../assets/icons/socials/whatsapp.svg" alt="WhatsApp" width="32" height="32"/> -->
            </ListTile>
            <section class="work-time">
              <h4>Работаем:</h4>
              <p v-for="(range, index) in schedule" :key="index"><b>{{ range.day }}</b> {{ range.time }}</p>
            </section>
            <div class="logo-with-gradient">
              <img src="../../../assets/full_logo.png" alt="Конструктор">
            </div>
          </div>
          <div style="flex: 2; overflow:hidden;">
            <iframe src="https://yandex.ru/map-widget/v1/?from=mapframe&ll=37.948754%2C48.034679&mode=whatshere&whatshere%5Bpoint%5D=37.948530%2C48.034498&whatshere%5Bzoom%5D=17&z=16.65"
              width="100%"
              height="410"
              frameborder="0"
              allowfullscreen="true"
              style="position:relative;"
              loading="lazy">
            </iframe>
          </div>
        </div>
      </div>
    </section>
    <section id="statistics-section">
      <div class="content">
        <h1 class="title" style="text-transform: uppercase">Статистика</h1>
        <div stack>
          <div class="statistics">
            <div class="statistic-border">
              <div class="statistic">
                <b class="value">100</b>
                <b class="type">заказов</b>
              </div>
            </div>
            <div class="statistic-border">
              <div class="statistic">
                <b class="value">100</b>
                <b class="type">довольных клиентов</b>
              </div>
            </div>
            <div class="statistic-border">
              <div class="statistic">
                <b class="value">500</b>
                <b class="type">проектов</b>
              </div>
            </div>
          </div>
          <img class="background-image" width="100%" src="../../../assets/images/cubes_background.jpg" alt="background" />
        </div>
      </div>
    </section>
    <section id="feedback-section">
      <div class="feedback content">
        <h1 class="title" style="text-transform: uppercase">Обратная связь</h1>
        <div class="contact-info">
          <input v-model="feedback.name" id="name" placeholder="Имя">
          <input v-model="feedback.email" type="email" id="email" placeholder="Email">
          <input v-model="feedback.phone" type="tel" id="phone" placeholder="Номер телефона">
        </div>
        <textarea v-model="feedback.text" placeholder="Хочу спросить"></textarea>
        <button @click="sendFeedbackRequest()" filled class="send-button">
          <span :class="{ show:feedbackState.requestState == RequestState.idle }">Отправить сообщение</span>
          <span :class="{ show:feedbackState.requestState == RequestState.pending }">Отправка...</span>
          <span :class="{ show:feedbackState.requestState == RequestState.success }">Отправлено!</span>
          <span :class="{ show:feedbackState.requestState == RequestState.failed }">Ошибка отправки!</span>
        </button>
        <div secondary style="text-align: center; user-select: none;">Заполните, пожалуйста, все поля</div>
      </div>
    </section>
  </PageLayout>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref } from "vue";
import PageLayout from "../../layouts/page-layout.vue";
import svgArgument1 from "../../ui/icons/svg-argument1.vue";
import svgArgument2 from "../../ui/icons/svg-argument2.vue";
import svgArgument3 from "../../ui/icons/svg-argument3.vue";
import svgArgument4 from "../../ui/icons/svg-argument4.vue";
import svgCubes from "../../ui/icons/svg-cubes.vue";
import ServiceBlock from "./ServiceBlock.vue";
import Service from "@/data/service/Service";
import VCarousel from '../../ui/carousel/VCarousel.vue'
import axios from 'axios';
import ListTile from "@/components/ui/list-tile/list-tile.vue";
import NamedField, { NamedFieldSet } from "@/core/NamedField"
import { RequestState } from "@/core/RequestState";
import ModalDialog from "@/components/ui/ModalDialog.vue";
import SvgDefaultArrow from "@/components/ui/carousel/svg-default-arrow.vue";


type GalleryImage = { id:number, image:string, alt:string }
const feedback = NamedFieldSet.wrap(reactive({
  name:new NamedField<string>("Имя"),
  phone:new NamedField<string>("Телефон"),
  email:new NamedField<string>("Почта (email)"),
  text:new NamedField<string>("Вопрос",undefined,(name,value)=>`${name}:\n${value}`)
}));
const feedbackState = reactive({
  requestState:RequestState.idle,
  timeoutId:undefined as number | undefined
});
const services = ref<Service[]>([]);
const gallery = ref<GalleryImage[]>([]);
const selectedGalleryImageIndex = ref(-1);
const selectedGalleryImage = computed(()=>gallery.value[selectedGalleryImageIndex.value]);
const schedule = ref<any[]>([]);
onBeforeMount(()=>{
  fetchServices();
  fetchGallery();
  fetchSchedule();
})
onBeforeUnmount(()=>{
  gallery.value = [];
})
async function fetchServices(){
  const { data } = await axios.get("/data/main-page/services.json");
  services.value = data;
}
async function fetchGallery(){
  // const { data } = await axios.get("/data/main-page/gallery.json");
  // gallery.value = data;
  for(let i = 0; i<18; i++){
    const num = i+1;
    gallery.value.push({
      id:num,
      image:`/assets/images/gallery/${num}.jpg`,
      alt:`Изображение галереи #${num}`
    } as GalleryImage);
  }
}
async function fetchSchedule(){
  const { data } = await axios.get("/data/schedule.json");
  schedule.value = data;
}
async function sendFeedbackRequest(){
  if(feedbackState.requestState == RequestState.pending) return;
  clearTimeout(feedbackState.timeoutId);
  feedbackState.requestState = RequestState.pending;
  const { data } = await axios.get("/Api/mail/sm.php", { params:{
      body: feedback.toString().replaceAll('\n','<br>')
    }
  });
  feedbackState.requestState = data===1?RequestState.success:RequestState.failed;
  if(feedbackState.requestState == RequestState.success){
    feedback.email = "";
    feedback.name = "";
    feedback.phone = "";
    feedback.text = "";
  }
  feedbackState.timeoutId = setTimeout(()=>{
    feedbackState.requestState = RequestState.idle;
  },2000);
}
function selectGalleryImage(index:number){
  selectedGalleryImageIndex.value = index;
}
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/theme/colors.scss";
@import "../../../assets/styles/theme/button.scss";
.header {
  padding: 10px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  nav{
    color: black;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 25px;
    &>*{
      text-align: left;
      line-height:1.5em;
    }
    a{
      display: inline-flex;
      justify-content: left;
      gap:10px;
      width: max-content;
      cursor: pointer;
      user-select: none;
      .nav-arrow {
        transform: rotate(90deg);
      }
    }
  }
}
section{
  .title{
    margin-left:5px;
    text-align: left;
    color: $secondaryColor;
  }
  .content{
    padding: 20px;
  }
}
.background-image{
  z-index: -1;
}
#first-impression {
  position: relative;
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #arguments-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      // margin: 10px;
      & > .argument {
        display: flex;
        text-align: left;
        align-items: center;
        color: black;
        padding: 10px;
        line-height: 1.5em;
        font-weight: 500;
        &>svg{
          $size: 55px;
          margin: 15px;
          width: $size;
          min-width: $size;
          height: $size;
          fill: $primaryColor;
        }
      }
      //grid styles
      & > .argument {
        border: 0.5px solid;
        --gradient-position: left bottom;
        box-sizing: content-box;
        border-image: radial-gradient(
            farthest-side at var(--gradient-position),
            $primaryColor 0%,
            transparent 100%
          )
          1;
        &:nth-child(1) {
          --gradient-position: right bottom;
        }
        &:nth-child(2) {
          --gradient-position: left bottom;
        }
        &:nth-child(3) {
          --gradient-position: top right;
        }
        &:nth-child(4) {
          --gradient-position: top left;
        }
      }
    }
  }
  & > .background-image {
    position: relative;
    max-width: 800px;
    margin-left: auto;
    display: grid;
    &>img{
      width: 100%;
      transform: scaleX(-1);
    }
    &>.ficus{
      width: 171px;
      transform: translateX(30px);
      position: absolute;
      right: 0;
      bottom: 30px;
      z-index: 2;
    }
    .front-shadow {
      display: flex;
      z-index:1;
      --shadow-top:15px;
      --shadow-blur:50px;
      --shadow-color: white;
      .background{
        height: 100%;
        width:30%;
        background: var(--shadow-color);
      }
      .shadow{
        width:100%;
        height: 100%;
        box-shadow: inset var(--shadow-blur) 0px var(--shadow-blur) 0px var(--shadow-color),
          inset 0px var(--shadow-blur) var(--shadow-top) 0px var(--shadow-color),
          calc(0px - var(--shadow-blur) * 2) 0px 0px calc(var(--shadow-blur) * 3) var(--shadow-color);
      }
    }
  }
  & > img {
    width: 100%;
    transform: scaleX(-1);
  }
}
#services-section{
  .services{
    display: flex;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 15px;
  }
  .background-image {
    display: flex;
    justify-content: center;
    &>.cubes{
      &>svg{
        width: 300px;

      }
      --offset-x: 50%;
      &:first-child{
        transform: translateX(calc(0px - var(--offset-x))) scaleX(-1);
      }
      &:last-child{
        transform: translateX(var(--offset-x));
      }
    }
  }
}
#statistics-section{
  .statistics{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    // background: #D0E0F3;
    .statistic-border{
      $radius: 140px;
      width: $radius;
      height: $radius;
      $background: white;
      padding: 18px;
      border-radius: $radius;
      background: $background;
      box-shadow: 2px -2px 4px 0px #59798369,
                  1px 3px 5px 0px #8ea4bb,
                  inset 0px -3px 10px 2px #cfe0f1;
      .statistic{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to bottom, #D0E0F3, $background);
        border-radius: inherit;
        .value{
          line-height: 1em;
          font-size: 2.5em;
          color: $secondaryColor;
        }
        .type{
          font-weight: 600;
          text-align: center;
          color: $primaryColor;
        }
      }
    }
  }
}
.logo-with-gradient{
  height: 90px;
  background: linear-gradient(to right,#aad1ff 0%, transparent 100%);
  padding: 10px;
  &>img{
    height: 100%;
    filter: brightness(100);
  }
}
#feedback-section{
  .feedback{
    display: flex;
    flex-direction: column;
    gap: 10px;
    &>.send-button{
      position: relative;
      padding: calc($buttonPadding + 0.5em);
      &>span{
        position: absolute;
        top:50%;
        left: 50%;
        translate: -50% -50%;
        transform-origin: center;
        transform: scale(0.9);
        opacity: 0;
        transition: 0.3s ease-out;
        &.show{
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  .contact-info{
    display: flex;
    gap: 10px;
    &>input{
      width: 100%;
    }
  }
}
#gallery{
  .gallery-image{
    cursor: pointer;
    &>img{
      transition: transform 0.15s ease-out;
    }
    &:hover>img{
      transform: scale(1.1);
    }
  }
  .gallery-modal-image{
    display: flex;
    max-width: 100%;
    max-height: 100%;
    width: 80vw;
    &>img{
      width: 95%;
      object-fit: contain;
    }
    &>.counter{
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
      color: white;
      filter: drop-shadow(0px 0px 1px black);
      user-select: none;
    }
  }
}
.default-arrow{
  fill: white;
  stroke: black;
  filter: drop-shadow(0px 0px 1px black);
  width: 20px;
  height: 100%;
  margin: auto 5px;
}
</style>
