<template>
    <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 6627.0669 11044.173"
   height="11044.173"
   width="6627.0669"
   xml:space="preserve"
   fill="#231f20"
   fill-opacity="1"
   fill-rule="nonzero"
   stroke="none"
   id="svg2"
   version="1.1"><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,11044.173)"
     id="g10"><g
       transform="scale(0.1)"
       id="g12"><g
         transform="scale(5.08166)"
         id="g14"><path
           id="path16"
           d="M 1559.25,0 C 1440.33,30.9139 1352.72,107.169 1268.35,192.18 897.404,565.897 529.471,942.843 149.85,1307.58 -52.3182,1501.83 -48.2895,1775.9 152.045,1975.56 2103.25,3920.1 4049.82,5869.31 5997.71,7817.21 c 220.44,220.44 220.54,449.6 -0.16,670.32 -1949.79,1949.87 -3898.08,3901.37 -5852.073,5846.97 -187.9321,187.2 -193.0186,471 -11.612,642.4 283.442,267.8 553.275,549.9 829.062,825.9 101.753,101.7 206.453,200.7 305.313,305.2 82.71,87.5 171.75,161.7 291.01,192.1 49.89,0 99.79,0 149.69,0 122.82,-36.7 212.22,-119.5 300.62,-207.9 2540.65,-2542.3 5082.28,-5083.6 7623.74,-7625.04 196.8,-196.81 196.74,-437.53 -0.14,-634.42 C 7091.7,5291.29 4550.03,2750.03 2009.54,207.589 1921.02,119.016 1831.32,37.1711 1708.94,0 c -49.9,0 -99.8,0 -149.69,0" /></g></g></g></svg>
</template>
<script setup>
</script>
<style scoped lang="scss">
svg{
  transition: all 0.1s ease-out;
  filter: drop-shadow(0px 0px 0px gray);
  &:hover{
    filter: drop-shadow(0px 1px 2px gray);
  }
  &:active{
    filter: drop-shadow(0px 0px 0px black);
  }
  &[flip-x]{
    transform: scaleX(-1);
  }
  &:not([disabled=false]){
    &[disabled],[disabled=true]{
      pointer-events: none;
      filter: contrast(0.5);
      fill: white;
    }
  }
}
</style>